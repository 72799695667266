.referral-section{
  .form-group{
    max-width: 100% !important;
    min-width: 600px;
    @media screen and (max-width: 767.98px){
      min-width: 100%;
    }
  & input{
    min-width: 400px;
    @media screen and (max-width: 767.98px){
      min-width: 100%;
    }
  }
  }
}
