@import "../../assets/sass/_variables";
@import "../../assets/sass/env";

.home-sports-section {
  padding-bottom: 1.875rem;

  .theme-card-wrap {
    transition: transform 0.15s ease-out 0s;

    &:hover {
      transform: translateY(-8px);
    }
  }

  .theme-card {
    text-align: center;
    min-height: 156px;
    cursor: pointer;

    h4 {
      color: var(--white);
      font-weight: 700;
      font-family: "Teko", sans-serif;
      font-size: 1.125rem;
      line-height: 1.25rem;
    }

    & svg {
      margin: 1.5rem auto;
      height: 36px !important;
      width: 36px !important;
    }

    .live-game-icon {
      margin: 1.5rem auto;
    }
  }

  .live-match-count-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .live-match-count {
    background: var(--liveMatchCountBg);
    box-shadow: 0 2px 5px 0 rgba(9, 27, 45, 0.47);
    border-radius: 22px;
    border: 1px solid var(--liveMatchCountBorder);
    padding: 0 1rem;
    min-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto 0;
    color: var(--white);
    position: absolute;
    bottom: 1.5rem;

    .live-dot {
      background: var(--live-green);
      height: 8px;
      width: 8px;
      border-radius: 100%;
      display: block;
      margin-right: 0.313rem;
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
}

.crypto-section {
  padding-bottom: 1.875rem;

  .theme-card-wrap {
    transition: transform 0.15s ease-out 0s;
    cursor: pointer;

    .theme-card {
      overflow: hidden;
      aspect-ratio: 148/202;
      position: relative;

      @media screen and (max-width: 1024.98px) {
        aspect-ratio: 167/202;
      }

      .trade-header {
        padding: 0 0.625rem;

        p {
          font-size: 12px;
          color: var(--gray-40);
          font-weight: 600;
          margin: 0;

          @media screen and (max-width: 1199.98px) {
            font-size: 10px;
          }
        }

        .trade-logo {
          margin-right: 0.313rem;
          height: 2.125rem;
          width: 2.125rem;
          padding: 0.25rem;
          border-radius: 0.375rem;
          background-color: var(--blue-55);
        }
      }

      .trade-card-content {
        color: #ffffff;
        padding: 0 0.625rem;

        .crypto-list-right span {
          font-size: 14px;
          font-weight: 700;
        }

        h4 {
          font-weight: 600;
          margin: 0;
          font-size: 0.875rem;
        }
      }

      h4 {
        color: var(--white);
        font-size: 14px;
        font-weight: 700;
        margin: 0;

        @media screen and (max-width: 1199.98px) {
          font-size: 12px;
        }
      }

      .trade-graph-wrap {
        position: absolute;
        bottom: -20px;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }

        .trade-graph-text {
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          bottom: 0.625rem;
          width: 100%;

          p {
            color: var(--white);
            font-size: 0.75rem;
            font-weight: 400;
            text-align: center;
            padding: 0 0.313rem;
          }
        }
      }
    }

    &:hover {
      transform: translateY(-8px);
    }
  }

  .loss-value {
    color: var(--red);
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0;

    img {
      margin-right: 0.313rem;
    }
  }

  .profit-value {
    color: var(--live-green);
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0;

    img {
      margin-right: 0.313rem;
    }
  }

  .trade-graph-text {
    h4 {
      font-weight: 300;
      font-size: 0.75rem;
    }

    p {
      font-weight: 400;
      line-height: 0.75rem;
    }
  }

  .Info-icon {
    img {
      width: 1rem;
    }
  }
}

.loss-change {
  font-size: 12px;
  font-weight: 600;
  padding: 0 0.625rem;
  color: var(--red);
}

.profit-change {
  font-size: 12px;
  font-weight: 600;
  padding: 0 0.625rem;
  color: var(--live-green);
}

.slide-wrap {
  .slick-slide {
    .px-06 {
      padding-right: 0.75rem;
    }
  }

  .slick-list {
    width: calc(100% + 10px);
  }
}

.clutch-section {
  padding-bottom: 1.875rem;

  .clutch-header {
    display: flex;
    align-items: center;

    img {
      margin-right: 0.615rem;
    }

    h4 {
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--white);
    }
  }

  .theme-card-wrap {
    cursor: pointer;
    transition: transform 0.15s ease-out 0s;

    .theme-card {
      padding: 1.5rem;

      @media screen and (max-width: 767.98px) {
        padding: 1rem 0.625rem;
      }

      p {
        color: var(--gray-20);
        font-size: 0.75rem;
        font-weight: 400;
        padding-top: 0.625rem;
        line-height: 1rem;

        @media screen and (max-width: 767.98px) {
          padding-top: 0.313rem;
        }
      }

      .clutch-card-content {
        padding: 1rem 0 0;

        h4 {
          color: var(--white);
          font-size: 0.813rem;
          font-weight: 500;
          line-height: 1rem;

          a {
            color: var(--gray-40);
            font-size: 0.813rem;
            font-weight: 400;
          }
        }

        .clutch-card-content-left {
          img {
            margin-right: 0.625rem;
          }
        }

        .btn-secondary {
          font-size: 0.75rem;
          height: 1.875rem;
          min-width: 5.813rem;
          border-radius: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &:hover {
      transform: translateY(-8px);
    }
  }
}

.poker-section {
  padding-bottom: 1.875rem;

  .theme-card-wrap {
    cursor: pointer;
    transition: transform 0.15s ease-out 0s;

    .theme-card {
      position: relative;
      min-height: 200px;

      &:before {
        position: absolute;
        content: "";
        background: url("#{$s3Path}/assets/images/homePage/poker-overlay.webp");
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;
      }

      h4 {
        color: var(--white);
        font-weight: 700;
        font-family: "Teko", sans-serif;
        font-size: 1.2rem;
        line-height: 1.8rem;
        text-align: center;
        margin: 0;

        @media (min-width: 992px) and (max-width: 1199.98px) {
          font-size: 1.2rem;
        }
      }

      p {
        text-align: center;
        color: var(--gray-20);
        font-size: 0.75rem;
        margin: 0;
      }

      .poker-card-content {
        position: relative;

        .poker-icon {
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        img {
          margin: 0 auto;
        }
      }
    }

    &:hover {
      transform: translateY(-8px);
    }
  }
}

.nft-section {
  padding-bottom: 1.875rem;

  .theme-card-wrap {
    cursor: pointer;
    transition: transform 0.15s ease-out 0s;

    .theme-card {
      .nft-img-wrap {
        position: relative;
        overflow: hidden;
        border-radius: 0.625rem;

        img {
          width: 100%;
        }

        .nft-badge {
          position: absolute;
          padding: 0 0.313rem;
          border-radius: 27px;
          background: var(--gray-80);
          min-height: 26px;
          min-width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--white);
          font-size: 0.625rem;
          font-weight: 600;
          left: 0.313rem;
          top: 0.313rem;
        }
      }

      .nft-content-wrap {
        padding: 0.313rem 0;

        .nft-content-left {
          h4 {
            color: var(--white);
            font-size: 1rem;
            font-weight: 600;
          }

          p {
            color: var(--gray-20);
            margin: 0;
            font-size: 0.875rem;
          }
        }

        .nft-content-right {
          span {
            color: var(--gray-20);
            font-size: 0.75rem;
          }
        }
      }
    }

    &:hover {
      transform: translateY(-8px);
    }
  }
}

.crypto-pay-section {
  text-align: center;

  .payment-options {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    a {
      padding: 0;
      display: inline-block;
    }

    svg, img {
      height: 29px;
      width: unset;
    }
  }

  h3 {
    color: var(--white);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  .theme-card {
    display: flex;
    padding: 1rem 1.5rem;
  }

  .btn-secondary {
    font-size: 1rem;
    font-weight: 500;
    border-radius: 0.5rem;
    padding: 0 1.5rem;
  }

  @media screen and (max-width: 1199.98px) {
    .theme-card {
      padding: 1rem;
    }

    .payment-options {
      gap: 10px;

      svg, img {
        height: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 767.98px) {
    .payment-options {
      gap: 10.5px;

      svg, img {
        height: 19px;
      }
    }

    h3 {
      font-size: 1rem;
      line-height: 1.25rem;
    }

    .theme-card {
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
    }

    .btn-secondary {
      width: 100%;
    }
  }
}

.third-party-login-section {
  text-align: center;

  .third-party-login-options {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    a {
      padding: 0;
      display: inline-block;
    }

    svg, img {
      height: 29px;
      width: unset;
    }
  }

  h3 {
    color: var(--white);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  .theme-card {
    display: flex;
    padding: 1rem 1.5rem;
  }

  .btn-secondary {
    font-size: 1rem;
    font-weight: 500;
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem;
  }

  @media screen and (max-width: 1199.98px) {
    font-size: 1.125rem;

    .theme-card {
      padding: 1rem;
    }
  }

  @media screen and (max-width: 767.98px) {
    font-size: 1rem;
    margin-bottom: 0.5rem !important;

    .theme-card {
      flex-wrap: wrap;
      gap: 1rem;

      h3, .third-party-login-options {
        flex: 100%;
        max-width: 100%;
      }
    }
  }
}

.theme-card {
  padding: 1rem 1.5rem;
  position: relative;

  @media screen and (max-width: 767.98px) {

    .third-party-login-options {
      gap: 22px;

      svg, img {
        height: 1.25rem;
      }
    }

    h3 {
      line-height: 1.25rem;
    }

    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    .btn-secondary {
      width: 100%;
    }
  }
}

.crypto-pay-section {
  .payment-options {
    svg, img {
      height: 24px;
    }
  }
}

.crypto-pay-section {
  .payment-options {
    svg, img {
      height: 24px;
    }
  }
}
