.verify-email {
  .modal-dialog {
    max-width: 540px !important;
  }

  .modal-content {
    overflow: hidden;

    .btn-close {
      background: var(--gray-60);
      opacity: 1;
      border-radius: 50%;
      border: 2px solid var(--gray-90n);
    }
  }

  .modal-body {
    padding: 0 !important;
    overflow: hidden;

    .popup-bg {
      position: relative;
      min-height: 219px;
      height: 100%;
      width: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        //filter: drop-shadow(0 0px 15px #232222);
      }
    }

    .popup-content {
      padding: 2.5rem;

      @media (max-width: 767px) {
        padding: 1.5rem;
      }

      h2 {
        font-family: "Rubik", sans-serif;
        color: var(--white);
        font-size: 42px;
        margin-bottom: 1rem;
      }

      p {
        color: var(--gray-20);
        font-size: 1rem;

        span {
          color: var(--white);
        }
      }

      .popup-note {
        font-size: 12px;
      }
    }
  }
}
