.tooltip-listing {
  padding-left: 1.25rem;
  list-style: disc;
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  margin-bottom: 0;
  opacity: 0.8;
}

.tip-amount {
  color: var(--tipAmountText) !important;
}

.chat-announcement-wrap {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0 1rem;
}

.user-social-list-wrap {
  margin-top: 0.75rem;

  a {
    height: 2rem;
    width: 2rem;
    border: 1px solid var(--gray-60);
    border-radius: 0.25rem;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
}

.chat-inner-header {
  h4 {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  svg {
    margin-right: 0.25rem;
  }

  a {
    color: var(--gray-40);
    font-weight: 500;
    font-size: 0.75rem;

    svg {
      margin-right: 0;
    }
  }
}

.chat-badge-wrap {
  .chat-badge-listing-wrap {
    margin-top: 0.75rem;
    opacity: 0.5;

    svg {
      height: 2rem;
      width: 2rem;
      opacity: 0.6;

      &:hover {
        opacity: unset;
      }
    }
  }
}

.private-link a svg {
  opacity: 0.5;
}

.profile-pic-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .profile-pic {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .image-upload-box {
    width: 100%;
    height: 180px;
    background-color: var(--gray-85);
    border-radius: 8px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23404D6AFF' stroke-width='4' stroke-dasharray='5%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
}

.preview-wrap {
  padding: 16px;
  background-color: var(--gray-80);
  border-radius: 8px;
  width: 100%;

  .preview-heading {
    background-color: var(--darkGray-40);
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }

  .slider-wrap {
    font-size: 1.25rem;
  }

  .form-range::-webkit-slider-runnable-track {
    height: 0.25rem;
    background-color: var(--gray-85);
  }

  .form-range::-webkit-slider-thumb {
    height: 1.25rem;
    width: 1.25rem;
    background-color: var(--white);
    margin-top: -0.5rem;
  }

  .react-transform-wrapper {
    .react-transform-component {
      height: 160px;
      width: 160px;
    }
  }

  .preview-circle-wrap {
    position: absolute;
    margin: auto;
    height: 160px;
    width: 160px;
    overflow: hidden;
  }

  .preview-circle {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: 0 0 2000px 2000px var(--gray-80);
    z-index: 1;
    width: 124px;
    height: 124px;
    border-radius: 50%;
    pointer-events: none;
  }
}

.rc-tooltip.chat-rules-overlay {
  box-shadow: var(--dropdownShadow);

  .rc-tooltip-arrow {
    left: 166px !important;
  }

  .rc-tooltip-content {
    .rc-tooltip-inner {
      padding: 16px 12px !important;
      border-radius: 0.5rem;
    }
  }
}
