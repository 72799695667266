.casino-card-slider {
  .slick-list {
    margin: 0 -0.375rem;
  }

  .slick-track {
    padding: 1.1875rem 0 0;

    @media screen and (max-width: 767.98px) {
      padding: .75rem 0 0;
    }
  }

  .slick-slide {
    height: auto;

    & > div {
      height: 100%;
    }
  }

  &-slide {
    padding: 0 0.375rem;
    height: 100%;
  }
}
