.bannerSection {
  text-align: center;
  position: relative;
  // max-width: 280px;
  margin-left: 3rem;
  @media screen and (max-width: 1024px) {
    margin-left: 0rem;
  }

  .bannerSection-info {
    color: #ffffff;
    font-size: 2rem;
    font-weight: 400;
    @media screen and (max-width: 1024px) {
      font-size: 1.5rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }

    &.banner-hadding2 {
      font-size: 2.75rem;
      font-weight: 700;
      @media screen and (max-width: 1024px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
        margin-bottom: 0 !important;
      }
    }
  }

  .banner-btn-wrap {
    button.btn.btn-secondary {
      @media screen and (max-width: 768px) {
        padding: 0.25rem 0.5rem;
        min-height: 2.5rem;
      }
    }
  }

  // @media screen and (max-width: 768px) {
  //   margin: 0 auto;
  // }

  &-image {
    max-width: 17.5rem;
  }

  &-content {

    a {
      position: relative;
      display: inline-block;
      color: var(--white);
      font-weight: 600;
      font-size: 0.75rem;
      letter-spacing: 2px;
      padding: 0.125rem 1rem;
      transition: opacity 0.3s;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        &:after {
          display: none;
        }
      }

      &:hover {
        opacity: .7;
      }

      &:after {
        position: absolute;
        content: "";
        right: 0;
        top: 4px;
        width: 1px;
        height: 70%;
        background: var(--gray-40);
      }


    }
  }

  .auth-social-heading {

    h6 {

      span {
        background-color: transparent;
      }
      &:before, &:after {
        position: absolute;
        content: "";
        right: 0;
        top: 10px;
        width: 70px;
        height: 1px;
        background: var(--gray-80);
      }

      &:before {
        left: 0;
      }
    }
  }
}
