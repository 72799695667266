.subpageHeader {
  // background-color: var(--gray-90);
  position: relative;
  display: flex;
  align-items: center;
  min-height: 334px;
  border-radius: 8px;
  overflow: hidden;

  @media screen and (max-width: 767.98px) {
    aspect-ratio: unset;
    min-height: 280px;
  }

  &-content {
    position: absolute;
    padding: 2rem 1rem;
    width: 100%;
    z-index: 1;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 767.98px) {
      text-align: center;
      padding: 2rem 1rem;
    }
  }

  &-image {
    // position: absolute;
    // left: 0;
    // top: 0;
    width: 100%;
    height: 100%;
    // object-fit: cover;
    object-position: right;
    border-radius: 8px;

    @media screen and (max-width: 767.98px) {
      object-position: center;
    }
  }

  h1 {
    position: relative;
    display: inline-flex;
    font-weight: 700;
    font-size: 3.125rem;
    line-height: 1;
    margin-bottom: 0;
    background: linear-gradient(180deg, #fefefe 0%, #cccfd1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Rubik", sans-serif;
    text-align: start;

    @media screen and (max-width: 767.98px) {
      font-size: 2rem;
      text-align: center;
    }

    svg {
      position: absolute;
      right: -8px;
      top: 12px;
      transform: translate(100%, -100%);

      @media screen and (max-width: 767.98px) {
        width: 30px;
      }
    }

    &:after {
      position: absolute;
      content: "";
      height: 1px;
      width: calc(100% - 60px);
      width: 100%;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to right, rgb(244, 245, 245), rgba(244, 245, 245, 0));

      @media screen and (max-width: 767.98px) {
        background-image: linear-gradient(90deg, rgba(244, 245, 245, 0) 0%, #F4F5F5 50%, rgba(244, 245, 245, 0) 100%);
        // width: 200px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }


  p {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: 5px;
    text-transform: uppercase;
    background: linear-gradient(360deg, #E29C00 0%, #FFC84C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (max-width: 767.98px) {
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 3px;
      margin-top: .5rem;
    }
  }

  &-gold {
    p {
      background: linear-gradient(180deg, #FFB018 5.97%, #E9AE1C 72.39%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
