// button.btn-close.btn-close-white {
//   position: absolute;
//   right: 30px;
//   top: 25px;
// }
// #login_modal.modal .modal-content {
//   background-color: var(--modalBG);
// }

// #login_modal .form-control {
//   background-color: var(--darkBlue);
//   border-radius: 60px;
//   border: 1px solid transparent;
//   padding: 15px 18px;
//   color: #fff
// }
// #login_modal .form-control:focus {
//   background-color: var(--darkBlue);
//   box-shadow: none !important;
//   color: var(--inputFocusText);
// }


#login_modal.modal {
  .modal-dialog {
    @media screen and (max-width: 575.98px) {
      margin: 0;
    }

    .modal-content {
      @media screen and (max-width: 575.98px) {
        height: 100%;
      }
    }
  }

  .register-wrap {
    @media screen and (max-width: 575.98px) {
      padding: 0.75rem 1.75rem;
      overflow-y: auto;
    }
  }

  .mob-login-header {
    @media screen and (max-width: 575.98px) {
      padding: 0.75rem 1.75rem 1rem;
      background-color: var(--gray-85);
      border-bottom: 1px solid var(--gray-80);
    }
  }

  .mob-login {
    @media screen and (max-width: 575.98px) {
      padding: 0;
    }
  }

  .btn-close {
    @media screen and (max-width: 575.98px) {
      background-color: transparent;
      border: none;
      top: 1.5rem;
      right: 1rem;

      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  .modal-body {
    overflow-x: hidden;
  }
}


.auth-form-wrap {
  padding: 24px 10px 24px 24px;

  @media screen and (max-width: 991.98px) {
    padding: 24px;
  }

  @media screen and (max-width: 575.98px) {
    padding: 0;
  }

  .auth-logo {
    text-align: center;

    svg {
      margin: 8px auto 32px;
      width: 238px;
      height: 48px;

      @media screen and (max-width: 575.98px) {
        width: 178px;
        height: 36px;
        margin: 4px auto 16px;
      }
    }
  }

  .auth-top-btn {
    .nav {
      gap: 0.25rem;

      .nav-item {
        flex: 1;

        .nav-link {
          background-color: var(--darkGray);
          border: 2px solid transparent;
          border-radius: 0.25rem;
          color: var(--gray-20);
          font-size: 0.875rem;
          font-weight: 500;
          max-height: 40px;
          line-height: 1.75rem;
          padding: 0.3125rem 1.625rem 0.25rem;
          width: 100%;

          @media screen and (max-width: 575.98px) {
            line-height: 1.5rem;
          }

          &.active,
          &:hover,
          &.active:hover {
            background-color: var(--gray-80);
            border: 2px solid transparent;
            color: var(--white);
          }
        }
      }
    }
  }

  .auth-btn-wrap {
    margin: 0.938rem 0;

    .btn {
      height: 44px;
    }
  }

  .auth-social-card {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      svg {
        width: 5rem;
      }
    }

    @media screen and (max-width: 767.98px) {
      width: 80px;

      & img {
        width: 100%;
      }
    }
  }

  form {
    .error-container {
      margin-top: 10px;
      height: 21px;

      p {
        margin: 0;
        font-size: 12px;

        @media screen and (max-width: 767.98px) {
          font-size: 12px;
        }
      }
    }
  }

  .flag-dropdown {
    background-color: transparent;
    border: none;
    padding-left: 6px;

    &.open {
      background: transparent;

      .selected-flag {
        background-color: transparent;
      }
    }

    .selected-flag:hover {
      background-color: transparent !important;
    }
  }

  .react-tel-input {
    input {
      background-color: var(--darkGray);
      border: 2px solid var(--darkGray);

      &:focus {
        box-shadow: none;
      }
    }

    .country-name {
      color: #6b6b6b;
    }

    .country-list {
      background-color: #0b1426;
    }
  }

}

.register-wrap {
  .forgot-password {
    color: var(--gray-40);
    font-weight: 500;
    font-size: 0.75rem !important;
  }
}

.twofa-wrap {
  p {
    color: var(--gray-40);
    font-weight: 400;
    font-size: 0.875rem !important;
  }
}
