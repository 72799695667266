:root {
  --p: 0;
  --b: 2px;
  --c: var(--red);
  --w: 16px;
}

.nav-link.cta-btn:hover,
.nav-link.cta-btn.active {
  background: var(--darkSlate);
  color: var(--white);
}

.modal-backdrop {
  display: none;
}

header {
  background: var(--gray-90);
  // box-shadow: var(--shadows-lg);
  border-bottom: 1px solid var(--gray-80);
  position: relative;
  padding: 0 1rem;
  z-index: 11;
  padding-right: 14px;
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 76px;

  @media screen and (max-width: 575.98px) {
    min-height: 64px;
  }

  .brand-logo {
    svg {
      width: 158px;

      @media screen and (max-width: 1199.98px) {
        width: 50px;
        height: 50px;
      }

      @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
      }

      // @media screen and (max-width: 575.98px) {
      //   width: 32px;
      //   height: 32px;
      // }
    }

    .before-login-logo {
      width: 158px !important;
    }
  }

  .header-middle-btn {
    gap: 0.5rem;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;

    @media screen and (max-width: 768px) {
      position: static;
      transform: none;
    }

    @media screen and (max-width: 575.98px) {
      margin-left: auto;
    }

    .btn-primary {
      background: var(--gray-80);
      border-color: var(--gray-80);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: var(--sidebarBtnGradient);
        border: 1px solid var(--gray-60);
      }

      &.currencies-btn {
        background: var(--darkGray);
        border: none;
        border-radius: 0.5rem;
        min-height: 2.75rem;

        &::after {
          margin-left: 0;
          color: var(--gray-20);
        }

        &:hover {
          background: var(--gray-80) !important;
          border-radius: 0.5rem;
        }

        @media screen and (max-width: 1400px) {
          border-radius: 6px 0 0 6px;

          &:hover {
            border-radius: 6px 0 0 6px;
          }
        }

        @media screen and (max-width: 575.98px) {
          padding: 0.25rem 0.5rem;
          min-height: 2.5rem;
        }
      }
    }

    .buy-crypto {
      color: var(--gray-20);
      background-color: var(--gray-80-50);
      border: none;

      &:hover {
        color: var(--white);
        border: none;
      }
    }
  }

  .header-social-links {
    a {
      height: 2.75rem;
      width: 2.75rem;
      border-radius: 0.625rem;
      background: var(--gray-80);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: background-color 0.3s ease 0s;

      svg {
        transition: fill 0.3s ease 0s;
      }

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      &:hover {
        background: var(--gray-60);

        svg {
          fill: var(--blue-300);
        }
      }
    }
  }

  &.sidebar-close {
    .brand-logo {
      @media only screen and (min-width: 768px) and (max-width: 1480.98px) {
        margin-left: 0;
      }
    }
  }

  .auth-btn {
    &.btn-primary {
      min-width: 120px;
      border: 1px solid var(--blue-300);
      background: transparent;
      color: var(--blue-300);

      &:hover {
        color: var(--blue-100);
        background: transparent !important;
        border-color: var(--blue-100);
      }

      @media screen and (max-width: 991.98px) {
        font-size: 0.875rem;
      }

      @media screen and (max-width: 767.98px) {
        min-width: 106px;
        font-size: 1rem;
        min-height: 2.5rem;
      }

      @media screen and (max-width: 575.98px) {
        min-height: 2rem;
        font-size: 14px;
        line-height: 1rem;
        min-width: auto;
        padding: 8px 12px;
      }
    }

    &.btn-secondary {
      min-width: 120px;

      @media screen and (max-width: 991.98px) {
        font-size: 0.875rem;
      }

      @media screen and (max-width: 767.98px) {
        min-width: 106px;
        font-size: 1rem;
        min-height: 2.5rem;
      }

      @media screen and (max-width: 575.98px) {
        min-height: 2rem;
        font-size: 14px;
        line-height: 1rem;
        min-width: auto;
        padding: 8px 12px;
      }
    }
  }

  .search-btn {
    width: 45px;
    // display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    & svg {
      height: 1.2rem;
      width: 1.2rem;
    }

    &.btn-primary {
      background: var(--gray-80);
      border-color: var(--gray-80);
      position: relative;
    }

    .theme-modal {
      .modal-dialog {
        position: relative;
      }
    }
  }

  .reward-menu-wrap {
    .reward-menu {
      min-width: 415px;
      left: -60% !important;
      overflow-x: hidden;
      overflow-y: auto;
      min-height: 310px;
      padding: 1.5rem 1.5rem 0 1.5rem;
      margin-bottom: 0;

      @media screen and (max-width: 767.98px) {
        min-width: 360px;
      }

      li {
        margin-bottom: 0.75rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .dropdown-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;
        padding: 0.313rem 0;

        &:hover {
          background: transparent;

          img {
            filter: none;
          }
        }

        .reward-menu-content {
          h4 {
            font-size: 0.875rem;
            text-transform: uppercase;
            padding-bottom: 0.1rem;
          }

          p {
            font-size: 0.75rem;
            font-weight: 300;
            color: var(--live-green);
          }
        }

        .btn {
          height: auto;
          padding: 0.2rem 1rem;
          font-size: 0.75rem;

          & svg {
            width: 1rem;
            height: 1rem;
            margin-right: 0.313rem;
          }

          &.btn-green {
            background: var(--live-green);

            &:hover {
              background: transparent;
              border-color: var(--blue-300);
            }
          }
        }

        .reward-menu-icon {
          margin-right: 0.313rem;

          & svg {
            height: 3rem;
            width: 3rem;
            margin: 0;
          }
        }
      }

      .reward-list {
        background-color: var(--gray-85);
        padding: .75rem 1rem;
        border-radius: 0.5rem;

        .bonus-type {
          display: flex;
          align-items: center;
          gap: 1rem;

          p {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 700;
            color: var(--white);
          }

          img {
            width: 44px;
          }
        }

        .bonus-btn {
          button {
            min-height: 2.5rem;
            padding: 0.25rem 1rem !important;
            justify-content: center;
            display: flex;
            align-items: center;

            @media screen and (max-width: 767.98px) {
              min-width: 110px !important;
              font-size: 0.875rem;
            }

            span {
              display: flex;
              align-items: center;
            }

            svg {
              width: 1rem;
              height: 1rem;
              margin-right: 0.313rem;
            }
          }
        }
      }

      .dropdown-btn-wrap {
        .btn-primary {
          background: var(--gray-80);

          &:hover {
            background: var(--secondaryRadialBtn);
            border-color: var(--secondaryBtnBorder);
          }
        }
      }
    }

    .view-dashboard {
      color: var(--gray-20);
      font-size: 1rem;
      font-weight: 500;

      &:hover {
        color: var(--white);
      }
    }
  }

  .provider-list {
    .casino-search {
      background: transparent;

      & .search-icon {
        top: 0.9rem;
      }

      & input {
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.19) !important;
      }
    }
  }

  .header-toggle-icon,
  .header-user-menu {
    margin-left: 2rem;

    @media screen and (max-width: 767.98px) {
      margin-left: 0.5rem;
    }

  }

  .reward-btn {
    .btn-primary {
      padding: 0.625rem 0.5rem;
      background: var(--gray-85);
      border: none;

      .dropdown-icon {
        width: 24px;
        height: 24px;
        color: var(--gray-20);
        border-radius: 0.5rem;
        background: var(--gray-80);
        line-height: 1.6;
      }

      &:hover,
      &:focus {
        .dropdown-icon {
          background-color: var(--gray-80);
        }
      }

      @media screen and (max-width: 767.98px) {
        padding: 0.25rem 0.5rem;
      }

      @media screen and (max-width: 575.98px) {
        min-height: 2.5rem;
        //  height: 2rem;
        width: 2.5rem;
        padding: 6px;

        svg {
          width: 22px;
        }
      }
    }
  }

  .reward-btn.show {
    .dropdown-icon {
      background-color: var(--gray-80) !important;
    }
  }

  .account-btn:hover .dropdown-icon {
    background-color: var(--gray-80) !important;
  }

  .top-header-right {
    display: flex;

    .btn-primary {
      position: relative;

      svg {
        position: absolute;
        transform: translate(-50%, -50%);

        &:nth-child(2) {
          opacity: 0;
        }
      }

      &.active {
        background: var(--gray-60) !important;

        svg {
          fill: var(--blue-300);
        }
      }

      &:hover {
        background: var(--gray-60) !important;

        svg {
          fill: var(--blue-300);
        }
      }
    }
  }
}

.search-dilog {
  max-width: 1408px;

  .search-modal {
    background-color: var(--darkGray) !important;

    .search-modal-wrap {
      padding: 2rem;

      @media screen and (max-width: 1199.98px) {
        padding: 2rem 2rem 5rem 2rem;
      }

      .nav-link {
        background: var(--gray-85);
        min-width: 100%;
        width: 100%;
        margin: 0;
        justify-content: flex-start;
        border: none;
        font-size: 0.875rem;
        font-weight: 500;
        border-radius: 0;
        padding: 0.6rem 1rem;
        min-height: 2.5rem;

        &.active,
        &:hover {
          background: var(--gray-90);

          & img {
            filter: brightness(10);
          }
        }

        & img {
          margin-right: 0.8rem;
          width: 1rem;
        }
      }

      .search-modal-right-section {
        @media screen and (max-width: 767.98px) {
          padding: 0;
        }

        .search-modal-heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1rem;

          h4 {
            color: var(--white);
            margin: 0;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.375rem;
          }
        }

        .casino-search {
          flex: 1;
          margin-right: 0;

          input {
            height: 48px;
          }
        }

        .search-filter-right {
          .btn-primary {
            font-size: 0.875rem;
            min-height: 48px;
            background: var(--gray-80);
            border-color: var(--gray-80);
            color: var(--gray-20);
            cursor: pointer;
            padding: 0.25rem 1.125rem;

            span {
              color: var(--white);
            }

            &:after {
              margin-left: 1rem !important;
              transition: transform 0.3s;
            }

            &.show,
            &:visited {
              background: var(--gray-80) !important;
              border-color: var(--gray-80) !important;

              &:after {
                transform: rotate(180deg);
              }
            }
          }

          @media screen and (max-width: 767.98px) {

            & .btn {
              width: 100%;
            }
          }

        }

        .global-casino-section {
          padding: 0 0.625rem;

          // margin: 1rem 0;
          .theme-card-wrap {
            margin: 0.5rem 0;
            width: 100%;
            min-width: 100%;
          }
        }

        .load-more-wrap {
          .btn {
            @media screen and (max-width: 767.98px) {
              justify-content: center;
            }
          }
        }

        .global-casino-section {
          .col-lg-2 {
            @media screen and (min-width: 1200px) {
              width: 14.285%;
            }
          }
        }
      }

      .profile-tabs {
        position: sticky;
        top: 1rem;
        background: var(--gray-85);
        border-radius: 0.625rem;
        padding: 0;
        overflow: hidden;
        max-width: 190px;
        margin-right: 2rem;
      }
    }
  }
}

.auth-modal-wrap {
  @media screen and (max-width: 1199.98px) {
    min-width: 80%;
  }
}

@media screen and (max-width: 767.98px) {
  .currency-wrapper .btn-wallet {
    padding: 4px 11px;
  }
}

header .menuBtn {
  display: none;
  z-index: 1;
}

header .menuBtn.toggleBtn {
  transform: scaleX(-1);
}

.chat-sidebar {
  position: fixed;
  right: 0;
  top: 4.75rem;
  background: var(--gray-90);
  height: calc(100dvh - 50px);
  width: 248px;
  border-left: 1px solid var(--gray-80);
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 575.98px) {
    top: 4rem;
  }

  & .chat-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &.chat-collapse {
    width: 352px;

    @media screen and (max-width: 767.98px) {
      width: 100%;
    }

    .chat-collapse-icon {
      transform: rotate(180deg);

      svg {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
    }

    .btn-secondary {
      min-width: 110px;
    }
  }

  @media screen and (max-width: 767.98px) {
    width: 100%;
  }

  .chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-80);
    padding: 1rem;
    margin-bottom: 1rem;

    .chat-btn {
      background: var(--gray-60) !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .language-btn {
      a {
        color: var(--white);
        font-weight: 600;
        font-size: 0.75rem;
        display: flex;
        align-items: center;

        i {
          font-size: 1rem;
        }
      }

      button {

        &:hover,
        &:active {
          background: var(--gray-60) !important;
        }
      }
    }

    // .chat-close-wrap {
    //   width: 60px;
    // }

    .btn-primary {
      background: transparent;
      border: none;
      width: 100%;
      font-size: 0.875rem;
      padding: 0 0.5rem;
      box-shadow: none;
      color: var(--gray-20);
      min-height: 2.25rem;
      font-weight: 500;

      & svg {
        margin-right: 0.3125rem;
        height: 1.125rem;
      }

      svg,
      path {
        transition: fill 0.3s ease 0s;
      }

      &.active,
      &:hover {
        border-color: transparent;
        background: transparent;
        color: var(--white);

        svg,
        path {
          fill: var(--blue-300);
        }
      }
    }

    .btn-secondary {
      height: 2.375rem;
      width: 100%;
      font-size: 0.74rem;
      margin: 0 0.313rem;
      padding: 0.25rem 0.625rem;
      box-shadow: none;

      @media screen and (max-width: 767.98px) {
        min-width: 140px;
      }

      &.active,
      &:hover {
        border-color: var(--blue-300);
        background: transparent;
        color: var(--white);
      }
    }

    & > a {
      width: 100%;
      color: var(--white);
      text-align: center;
      font-size: 0.74rem;
      background: var(--gray-85);
      min-height: 2.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.625rem;
      margin: 0 0.313rem;
      border: 1px solid var(--gray-85);

      &.active,
      &:hover {
        background: var(--darkGray);
        border-color: var(--secondaryBtnBorder);
        color: var(--secondaryBtnBorder);
      }
    }

    .chat-close {
      background: transparent;
      padding: 0;
      // margin: 0 0.2rem;
      // width: 1.5rem;
      // flex: 0 0 1.5rem;
      align-items: center;
      display: flex;
      justify-content: center;
      border: none;
      position: relative;
      color: var(--gray-20);

      &.active,
      &:hover {
        background: transparent;
        border-color: transparent;
        color: var(--blue-300);
      }
    }

    .chat-collapse-icon {
      background: transparent;
      padding: 0;
      margin: 0 0.2rem;
      width: 5rem;
      align-items: center;
      display: flex;
      justify-content: center;
      border: none;
      // flex: 0 0 2rem;
      position: relative;

      &.active,
      &:hover {
        background: transparent;
        border-color: transparent;

        svg {
          path:nth-child(1) {
            stroke: var(--blue-300);
          }

          path:nth-child(2) {
            fill: var(--blue-300);
          }
        }
      }

      @media screen and (max-width: 991.98px) {
        display: none;
      }
    }

    .dropdown-toggle {
      &::after {
        display: inline-block;
      }
    }

    .dropdown-menu {
      min-width: 100%;
      padding: 0.25rem;
      border-radius: 0.5rem;

      a {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        gap: 0.75rem;
        width: 128px;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        font-weight: 400;

        img {
          width: 18px;
          object-fit: cover;
        }
      }
    }
  }

  .chat-announcement-card {
    background: var(--blue-300-gradient);
    border-radius: 0.313rem;
    margin-bottom: 0.25rem;
    padding: 0.625rem;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      color: var(--white);
      font-size: 0.75rem;
      margin: 0;
      padding-right: 0.4rem;
      line-height: 1.3;
      word-break: break-all;
    }

    a {
      color: var(--white);
      font-size: 0.625rem;
    }
  }

  .chat-content-scroll {
    // height: calc(100vh - 250px);
    // overflow-y: auto;
    // margin-bottom: 22px;
    // border-bottom: 2px solid var(--gray-80);
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    min-height: 0px;
    overflow-wrap: break-word;
    overflow: hidden auto;
    margin-bottom: 30px;
    border-bottom: 2px solid var(--gray-80);

    @media screen and (max-width: 1199.98px) {
      height: calc(100vh - 315px);
    }

    @media screen and (max-width: 767.98px) {
      height: calc(100vh - 315px);
    }

    .empty-chat {
      width: 100%;
      padding: 5px;
    }

    .chat-content-wrap {
      padding: 1rem 1rem 0.5rem 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .chat-bubble:nth-child(1) {
        margin-top: auto;
      }

      .new_message {
        color: #00a4fa;
        text-align: center;
      }

      .new_message::before {
        background-color: #00a4fa;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 100%;
      }

      .new_message::before {
        right: 0.5em;
        margin-left: -50%;
      }

      @media screen and (max-width: 767.98px) {
        width: 100%;
        height: calc(100vh - 260px);
      }

      .chat-card {
        margin: 0.5rem 0;
        border-radius: 0.5rem;
        background: var(--gray-80-50);
        padding: 0.75rem;
        color: var(--white);
        word-break: break-word;
        position: relative;

        &:hover {
          background: var(--gray-80);
        }

        h4 {
          font-weight: 600;
          padding-right: 0.313rem;
          cursor: pointer;
        }

        p {
          span {
            // opacity: 0.8;
            font-weight: 400;
          }

          .tagged-name {
            color: var(--white);
            font-weight: 400;
          }

          .chat-msg {
            color: var(--gray-10);
          }
        }

        .user-icon {
          width: 1rem;
          margin-right: 0.313rem;
        }

        &:first-child {
          margin-top: auto;
        }

        .chat-card-head {
          .chat-badge-lising {
            margin: 0 0.1rem;
            display: flex;

            svg {
              margin: 0 0.1rem;
            }
          }

          .chat-avtar {
            img {
              object-fit: cover;
              border-radius: 12px;
            }
          }

          .user-clr-one {
            // color: var(--white);
            // position: relative;
            // top: -2px;
            // margin-right: 4px;
            margin-bottom: 0;

            img {
              object-fit: cover;
              border-radius: 12px;
            }

            .mod-badge {
              font-weight: 600;
              font-size: 12px;
              border-radius: 4px;
              margin-right: 4px;
              color: var(--darkGray);
              line-height: 18px;
              min-height: 18px;
              width: 35px;
              padding: 0 4px;
              display: inline-block;
              cursor: pointer;
            }

            .chat-user-label {
              // display: inline-block;
              vertical-align: middle;
              // max-width: 70px;
              font-weight: 600;
              font-size: 0.875rem;
              color: var(--white);
            }

            .gif_img {
              pointer-events: none;
              cursor: default;
              height: 60px;
              // height: 50%;
            }
          }

          .gif-chat-box {
            display: flex;
            align-items: center;

            .chat-user-label {
              white-space: nowrap;
            }

            .gif_img {
              max-width: 75px;
              // width: 50%;
            }
          }
        }

        .chat-time {
          font-size: 0.625rem;
          font-weight: 300;
          color: var(--gray-20);
        }

        .chat-text {
          color: var(--gray-10-80);
          word-break: break-all;
          font-size: 0.875rem;
          line-height: 26px;
        }

        .report-user-wrap {
          padding: 0.2rem;
          min-width: 32px;
          min-height: 30px;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0.313rem;
          top: 0;
          display: none;
          cursor: pointer;
        }

        &:hover {
          .report-user-wrap {
            display: flex;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .chat-bottom-section {
    padding: 1rem;
    position: relative;
    top: -1.875rem;

    @media screen and (max-width: 1199.98px) {
      padding: 1rem 1rem 5.25rem 1rem;
    }

    .chat-typing {
      position: relative;

      textarea {
        background-color: var(--darkGray) !important;
        // height: 2.75rem !important;
        font-size: 0.875rem !important;
        border: 2px solid var(--gray-80) !important;
        padding-right: 4.375rem !important;
        border-radius: 0.5rem;
        margin-bottom: 0.625rem;
        color: var(--gray-40);
        font-family: "Rubik", sans-serif !important;
        // top: -0.925rem !important;
        // max-height: 10rem;
        // overflow: auto;

        &:focus {
          outline: none;
          color: var(--white);

          // border-color: var(--primaryBtnBorder) !important;
          &::placeholder {
            color: var(--white) !important;
          }
        }
      }

      div {
        .giphy-carousel {
          width: 285px;
          position: absolute;
          bottom: 79px;
          right: 0;

          .giphy-gif {
            width: 100px !important;
            height: 100px !important;
          }

          .giphy-attribution {
            display: none !important;
          }
        }

        button {
          border: 1px solid transparent;
          background-color: transparent !important;
        }
      }

      .chat-input-cta {
        position: absolute;
        z-index: 1;
        right: 0.625rem;
        bottom: 0.5rem;

        svg {
          cursor: pointer;
          margin: 0 0.2rem;
        }
      }
    }

    .chat-details-wrap {
      margin-top: 0.625rem;

      & .btn {
        min-height: 1.688rem;
        padding: 0 0.5rem;
        font-size: 0.74rem;
        border-radius: 0.375rem;
        // margin-left: 0.313rem;
      }

      & img {
        margin-right: 0.313rem;
        width: 1rem;
        height: 1rem;
        border-radius: 1rem;
      }

      a {
        color: var(--gray-10);
        font-weight: 400;
        font-size: 0.875rem;
        display: flex;
        gap: 8px;
        padding: 0.45rem;
        align-items: center;
        width: 8rem;
        height: 2rem;

        i {
          font-size: 1rem;
        }
      }

      & .dropdown-item {
        & img {
          margin-right: 0;
        }
      }

      .chat-rules {
        background-color: var(--gray-80);
        padding: 7px;
        border-radius: 4px;
        width: auto;

        &:focus {
          background: var(--gray-60);

          svg {
            path {
              stroke: var(--blue-300);
            }
          }
        }
      }

      ul {
        background-color: var(--gray-80);
        padding: 12px !important;
        min-width: 9rem;
        border-radius: 0.5rem;
        border: none;
        box-shadow: var(--dropdownShadow);

        li {
          border-radius: 4px;

          &:hover {
            background: var(--gray-60);

            a {
              color: var(--gray-10);

              &:hover {
                background: transparent;
                color: var(--white);
              }
            }
          }
        }
      }

      .progress-wrap {
        border: 2px solid var(--c);
        border-radius: 50%;
        height: 14px;
        width: 14px;
        margin-right: 6px;

        .pie {
          width: var(--w);
          aspect-ratio: 1;
          position: relative;
          display: inline-grid;
          // margin:5px;
          place-content: center;
          font-size: 25px;
          font-weight: bold;
          font-family: sans-serif;
        }

        .pie:before,
        .pie:after {
          content: "";
          position: absolute;
          border-radius: 50%;
        }

        .pie:before {
          inset: 2px;
          background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
            conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
          -webkit-mask: radial-gradient(farthest-side,
              #0000 calc(99% - var(--b)),
              #000 calc(100% - var(--b)));
          mask: radial-gradient(farthest-side,
              #0000 calc(99% - var(--b)),
              #000 calc(100% - var(--b)));
        }

        .pie:after {
          inset: calc(50% - var(--b) / 2);
          background: var(--c);
          transform: rotate(calc(var(--p) * 3.6deg)) translateY(calc(50% - var(--w) / 2));
        }

        .animate {
          animation: p 1s 0.5s both;
        }

        .no-round:before {
          background-size: 0 0, auto;
        }

        .no-round:after {
          content: none;
        }

        @keyframes p {
          from {
            --p: 0;
          }
        }
      }

      .text-limit {
        width: 25px;
        font-size: 0.875rem;
      }

      .btn-primary {
        font-weight: 400 !important;
        font-size: 0.875rem !important;
        min-height: 2rem;
        min-width: 100px;

        &:hover,
        &:focus,
        &:active {
          background: var(--gray-60) !important;
          border-color: var(--gray-60) !important;
          color: var(--white);
        }
      }

      .btn-secondary {
        font-weight: 500 !important;
        font-size: 1rem !important;
        max-width: 56px;
        min-width: 56px;
        min-height: 32px;
      }

      .dropdown-toggle {
        &::after {
          margin-left: 0.5rem;
        }
      }
    }

    .gif-input {
      height: 2rem !important;
      width: 80%;
      margin: 0 auto 0.25rem !important;
    }
  }
}

.user-profile-modal {
  .modal-body {
    padding: 0.625rem !important;
  }

  .btn-close {
    background-color: var(--gray-60) !important;
    border: 2px solid var(--gray-90) !important;
    opacity: 1 !important;
  }

  .modal-content {
    @media screen and (max-width: 1199.98px) {
      margin-bottom: 5rem;
    }

  }
}

.chat-modal {
  color: var(--white);

  .btn-close {
    position: absolute;
    height: 1rem;
    width: 1rem;
    right: 0.313rem;
    top: 0.313rem;
    border-radius: 100%;
    background: var(--gray-80);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 767.98px) {
      right: 0.625rem;
      top: 0.625rem;
    }

    &:hover {
      opacity: 1;
    }

    svg {
      width: 0.625rem;
      height: 0.625rem;
    }
  }

  .modal-dialog {
    max-width: 442px;

    @media screen and (max-width: 767.98px) {
      max-width: 100%;
      padding: 0.625rem;
      margin: 0;
    }
  }

  .chat-modal-header-wrap {
    background: var(--gray-80-50);
    border-radius: 0.625rem;
    margin-bottom: 0.5rem;

    h4 {
      text-transform: capitalize;
      margin: 0;
      padding-right: 1rem;
      font-size: 1.125rem;
      font-weight: 600;
    }

    .tip-btn {
      height: 1.5rem;
      min-height: 1.5rem;
      background: var(--gray-80);
      border: 1px solid var(--blue-300);
      border-radius: 0.313rem;
      color: var(--white);
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem 0 0.313rem;
      margin-right: 0.75rem;

      & img {
        width: 0.938rem;
        height: 0.938rem;
      }
    }

    .edit-btn {
      min-height: unset;
      height: 1.5rem;
      width: 1.5rem;
      border: 1px solid var(--gray-60);
      border-radius: 0.313rem;
      color: var(--gray-40);
      font-size: 0.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0 0 0 auto;

      i {
        font-size: 13px;
      }
    }

    .chat-modal-header-right {
      flex: 1;

      .progress {
        height: 6px;
      }
    }
  }

  .chat-modal-content {
    background: var(--gray-80-50);
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding: 1rem;

    .chat-bets-wrap {
      column-gap: 1rem;
      row-gap: 0.5rem;
    }

    .chat-bets {
      flex: 0 0 calc(50% - 1rem + (1rem / 2));
      max-width: calc(50% - 1rem + (1rem / 2));
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1;

      h4 {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1;
        color: var(--gray-40);

        @media screen and (max-width: 767.98px) {
          line-height: 18px;
        }
      }

      p {
        padding-left: 0.313rem;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1;
        color: var(--white);
      }
    }
  }

  .progrees-content {
    p {
      font-size: 0.875rem;
      color: var(--gray-40);
      font-weight: 500;

      span {
        color: var(--live-green);
        padding: 0 0.2rem;
        font-weight: 600;
      }
    }
  }

  .favourites-game-wrap {
    margin-right: 1rem;

    .favourites-game-img {
      height: 78px;
      width: 78px;
      border-radius: 0.313rem;
      overflow: hidden;
      margin-right: 1rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    .favourites-game-right {
      p {
        font-size: 0.75rem;
        color: var(--gray-20);
        font-weight: 600;
      }

      h4 {
        color: var(--white);
        font-size: 1rem;
        max-width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 700;
      }
    }
  }

  .chat-modal-profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap: 0.75rem;

    .chat-modal-header-details {
      display: flex;
      width: 100%;
      padding-right: 1rem;
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  .chat-modal-profile-img {
    padding: 1rem;
    border-right: 1px solid var(--gray-80);

    img {
      flex: 0 0 5rem;
      max-width: 5rem;
      height: 5rem;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
  }

  .chat-modal-header-left {
    width: 4.375rem;

    & img {
      width: 100%;
      max-width: 62px;
      height: auto;
    }
  }

  .modal-body {
    padding: 0.625rem;
    background: var(--gray-90);
    border-radius: 1rem;
    border: 1px; //solid var(--gray-80)
  }
}

.account-btn-mid-content {
  font-weight: 600;

  @media screen and (max-width: 767.98px) {
    p {
      font-size: 0.74rem;
    }
  }
}

.btn {
  &.balance-btn {
    @media screen and (max-width: 767.98px) {
      font-size: 0.74rem;
    }

    &:hover {
      background: var(--darkGray);
      cursor: default;
    }
  }

  @media screen and (max-width: 767.98px) {
    padding: 0.25rem 0.625rem;
  }
}

.chat-modal-wrap {
  .modal-dialog {
    position: relative;
    border-radius: 0.625rem;
    min-width: 620px;
  }

  .btn-close {
    filter: invert(10);
    opacity: 1;
    width: 0.625rem;
    background-size: 0.625rem;
    right: 0.313rem;
    top: 0.313rem;
    position: absolute;
    z-index: 1;
    box-shadow: none;
  }
}

.upload-modal {
  .modal-dialog {
    max-width: 468px !important;

    .btn-close {
      border-radius: 100%;
      background: var(--gray-80);
      opacity: 1;
      top: 1.5rem;
      right: 1.5rem;
    }

    .profile-pic-wrapper {
      .image-upload-box {
        position: relative;

        svg {
          width: 45px;
        }

        .drop-title {
          margin-bottom: 0;
          font-weight: 500;
          font-size: 0.875rem;

          span {
            color: var(--blue-300);
            text-decoration: underline;

            &:hover {
              text-decoration: none;
              cursor: pointer;
            }
          }

          ::-webkit-file-upload-button {
            cursor: pointer;
          }
        }

        .drop-limit {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 0.875rem;
          color: var(--gray-40);
        }
      }

      input[type="file"] {
        position: absolute;
        top: -2px;
        height: 20px;
        right: 0;
        width: 50px;
        cursor: pointer;
        opacity: 0;
      }
    }
  }

  .modal-footer {
    .btn-primary {
      border: 1px solid var(--blue-300);
      color: var(--blue-50);
    }
  }
}

.tip-modal {
  .modal-dialog {
    max-width: 400px;
    min-width: 400px;

    @media screen and (max-width: 767.98px) {
      max-width: 100%;
      min-width: 100%;
    }

    .modal-content {
      overflow: hidden;

      h3 {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
      }

      .form-label,
      .form-check label {
        font-size: 0.75rem;
      }

      .modal-footer {
        background: var(--gray-85);
        padding: 1.5rem;
        justify-content: flex-start;
      }
    }
  }
}

#profile-pic-modal {
  .modal-footer {
    background: transparent;
  }
}

.cashier-modal {
  z-index: 1202;

  .modal-dialog {
    min-width: 805px;

    @media screen and (max-width: 767.98px) {
      height: 100%;
    }

    .btn-close {
      @media screen and (max-width: 767.98px) {
        top: 25px;
      }
    }

    .modal-content {
      border-radius: 12px;

      @media screen and (max-width: 1024.98px) {
        border: 0;
        border-radius: 0;
      }

      .modal-body {
        @media screen and (max-width: 767.98px) {
          display: flex;
          flex-direction: column;
          background-color: var(--gray-90);
        }
      }

      .tab-content {
        @media screen and (max-width: 767.98px) {
          flex: 1;
        }
      }

      .tab-pane {
        @media screen and (max-width: 767.98px) {
          height: 100%;
        }
      }

    }

    @media screen and (max-width: 1024.98px) {
      min-width: 100%;
      margin: 0;
      padding: 0;
      border-radius: 0;
      // height: 100%;
    }

    .modal-body {
      padding: 1.5rem;

      @media screen and (max-width: 1024.98px) {
        padding: 1rem;
      }

      .nav-link {
        color: var(--gray-40);
        opacity: 0.8;
      }

      .nav-tabs .nav-item .nav-link:hover,
      .nav-tabs .nav-item .nav-link.active,
      .nav-pills .nav-item .nav-link:hover,
      .nav-pills .nav-item .nav-link.active {
        color: var(--white);
        border-color: var(--gray-80);
      }
    }

    .cashier-heading {
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;

      @media screen and (max-width: 767.98px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 1rem;
        margin-bottom: 1rem;
      }

      h3 {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        color: var(--white);
        font-weight: 700;

        @media screen and (max-width: 767.98px) {
          font-size: 1rem;
        }
      }

      a {
        color: var(--blue-300);
        font-size: 1rem;
        padding-left: 1rem;
        font-weight: 500;
        line-height: 1;
        text-decoration: underline;
      }

      .currency-icon {
        height: 30px;
        width: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.625rem;

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .left-arrow {
        padding: 0;

        svg {
          filter: brightness(10);
          width: 1rem;
          height: 1rem;
          margin-right: 1rem;
        }
      }
    }

    .modal-inner-card {
      background: var(--gray-85);
      padding: 1.5rem;
      border-radius: 0.625rem;
      color: var(--gray-20);

      @media screen and (max-width: 767.98px) {
        padding: 1rem;
      }

      p {
        margin-bottom: 0;
        padding-right: 2rem;
        line-height: 1.6;
        color: var(--gray-20);
        font-size: 1rem;
        font-weight: 400;
        font-style: normal;
      }

      .qr-code {
        canvas {
          border: 5px solid var(--white);
        }

        @media screen and (max-width: 767.98px) {
          margin-bottom: 1rem;
          margin-top: 1rem;
        }

        img {
          width: 100%;
        }
      }

      .change-username {
        label {
          @media screen and (max-width: 767.98px) {
            font-size: 0.75rem;
          }

          span {
            color: var(--red);
          }
        }

        .form-group {
          position: relative;

          .btn-secondary {
            position: absolute;
            right: 0.35rem;
            top: 0.375rem;
            min-height: 2.25rem;
            font-size: 0.813rem;
            display: flex;
            align-items: center;
          }

          .form-control {
            @media screen and (max-width: 767.98px) {
              padding-right: 5.7rem;
            }

            &:focus {
              border-color: var(--secondaryBtnBorder);
            }
          }
        }

        p {
          color: var(--white);
          text-transform: uppercase;
          font-size: 0.875rem;
          line-height: 1.75rem;
          font-weight: 700;
        }

        .form-text-wrap {
          margin: 0.313rem 0;

          p {
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1.063;
            color: var(--gray-40);
            text-transform: capitalize;
          }
        }

        .btn-primary {
          background: var(--gray-80);

          &:hover {
            background: var(var(--secondaryBtn));
          }
        }
      }

      .currency-converter {
        .form-group {
          img {
            position: absolute;
            left: 1rem;
            top: 1rem;
            height: 20px;
            width: 20px;
          }

          input {
            padding-left: 2.5rem;
          }
        }

        span {
          padding: 0 1rem;
          color: var(--white);
        }

        button > span {
          padding: 0;
        }
      }

      .payment-left-card {
        @media screen and (max-width: 767.98px) {
          flex-direction: column;
        }

        h4 {
          line-height: 1.6;
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 0;
          font-style: normal;
          color: var(--gray-20);

          @media screen and (max-width: 767.98px) {
            margin-bottom: 1.5rem;
          }
        }
      }

      .payment-card-center {
        @media screen and (max-width: 767.98px) {
          display: flex;
          justify-content: center;
        }

        img {
          margin: 0 0.25rem;
          max-width: 2.875rem;
          max-height: 1.5rem;
        }
      }

      .payment-card-right {
        text-align: center;

        button.btn-primary {
          background-color: var(--gray-80);
          color: var(--gray-20);
        }

        .btn {
          @media screen and (max-width: 600px) {
            width: 100%;
          }


        }

        @media screen and (max-width: 767.98px) {
          margin-top: 1.5rem;
        }
      }
    }

    .bitcoin-instruction-list {
      margin-top: 1.2rem;
      margin-bottom: 0;

      li {
        font-size: 0.865rem;
        font-weight: 500;
      }
    }

    .modal-btn-wrap {
      @media screen and (max-width: 767.98px) {
        margin-top: 1rem;
      }

      .btn {
        margin-left: 1rem;
        line-height: 1rem;
        height: 48px;

        @media screen and (max-width: 767.98px) {
          width: 100%;
          margin-left: 0;
          margin-bottom: 1rem;
        }
      }
    }

    .coupon-section {
      text-align: center;
      padding-bottom: 2rem;
      padding-top: 0.5rem;
      background-color: var(--gray-85);
      border-radius: 8px;

      @media screen and (max-width: 767.98px) {
        height: 100%;
        padding-top: 1.75rem;
      }

      img {
        width: 8rem;
        height: 8rem;
        margin: 0 auto;
      }

      h4 {
        color: var(--white);
        font-weight: 700;
        font-size: 1.25rem;
        margin-bottom: 0 !important;
      }

      .form-group {
        max-width: 220px;
        margin: 1.25rem auto 1.5rem;
      }

      p {
        color: var(--white);
        font-weight: 500;

        a {
          color: var(--blue-300);
          padding-left: 0.2rem;
          display: inline-block;
        }
      }
    }

    .custom-tabs-nav {
      width: calc(100% - 1.75rem);
      margin-bottom: .5rem;

      @media screen and (max-width: 767.98px) {
        margin-bottom: 0;
      }
    }

    .nav-pills {
      @media screen and (max-width: 767.98px) {
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 100%;
      }
    }

    .btn-info {
      @media screen and (max-width: 767.98px) {
        font-size: 0.75rem;
      }
    }

    .modal-content {
      height: 100%;
    }
  }

  .error-msg {
    margin: 0;
    position: absolute;
  }
}

.cashier-btn {
  .nav {
    gap: 0.75rem;

    .nav-item {
      .nav-link {
        background-color: var(--gray-85);
        border: 2px solid var(--gray-85);
        border-radius: 0.625rem;
        color: var(--gray-20);
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.75rem;
        padding: 0.375rem 1.625rem;

        &:hover {
          background-color: var(--gray-60);
          border: 2px solid var(--gray-60);
          color: var(--blue-300);
        }

        &.active,
        &.active:hover {
          background-color: var(--gray-85);
          border: 2px solid var(--blue-300);
          color: var(--blue-300) !important;
        }
      }
    }
  }
}

.remove-blur {
  backdrop-filter: blur(0) brightness(1);
}

.mob-reward-btn {
  border: none !important;
  background: var(--gray-80-50) !important;
}
